<template>
  <div class="content-three">
    <div class="regresar">
      <router-link to="/" class="link">
        <v-icon size="x-large" icon="mdi-arrow-left-circle"></v-icon><span>{{ $t('index.regresar') }}</span>
      </router-link>
    </div>
    <div class="content-body">
      <v-container>
        <v-row>
          <v-col  cols="12" sm="12" md="12">
            <span class="name-section">{{ $t('sections.subtitle-three') }}</span>
            <p class="intro">
              {{ $t('section-three.introduction') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.content-three{
  padding: 20px;
  border-radius: 10px;
  .regresar{
    margin-bottom: 5px;
    a, a:visited, a:hover, a:active, a:focus {
      text-decoration: none;
      color: white;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      font: inherit;
      line-height: normal;
      outline: none; /* Para quitar el borde de enfoque */
      display: flex;
      span{
        font-size: 25px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .regresar, .content-body{
    padding: 30px;
    background: #8B5FC3;
    color: white;
    border-radius: 20px;
    .intro{
      font-size: 14px;
      font-weight: 200;
      margin-top: 30px;
    }
  }

  .name-section{
    font-weight: 700;
  }
}

@media only screen and (max-width: 600px) {
  .content-three{
    padding:0px;
    .regresar, .content-body{
      padding: 5px !important;
    }
  }
}
</style>
