import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/styles/global.scss';
import { createI18n } from 'vue-i18n';  // Importa createI18n
import messages from '@/i18n/es.json';


const i18n = createI18n({
    locale: 'en', // idioma predeterminado
    fallbackLocale: 'es', // idioma de respaldo
    messages, // mensajes de localización
});

const app = createApp(App);
const vuetify = createVuetify();

app.use(router);
app.use(vuetify);
app.use(i18n);  // Usa i18n correctamente
app.mount('#app');
