<template>
   <div class="contenedor-option">
    <router-link to="/services">
    <CardOption
        :revert="false"
        :title="$t('sections.title-one')"
        :sub-title="$t('sections.subtitle-one')"
        number="01"
        hover-bg-color="#B37A35"
    />
    </router-link>
     <router-link to="/projects">
    <CardOption
        :revert="true"
        :title="$t('sections.title-two')"
        :sub-title="$t('sections.subtitle-two')"
        number="02"
        hover-bg-color="#A38902"
    />
    </router-link>
    <router-link to="/innovation">
    <CardOption
        :revert="false"
        :title="$t('sections.title-three')"
        :sub-title="$t('sections.subtitle-three')"
        number="03"
        hover-bg-color="#8B5FC3"
    />
    </router-link>
    <router-link to="/answers">
    <CardOption
        :revert="true"
        :title="$t('sections.title-four')"
        :sub-title="$t('sections.subtitle-four')"
        number="04"
        hover-bg-color="#BB6363"
    />
    </router-link>
    <router-link to="/contact">
    <CardOption
        :revert="false"
        :title="$t('sections.title-five')"
        :sub-title="$t('sections.subtitle-five')"
        number="05"
        hover-bg-color="#365A91"
    />
    </router-link>
   </div>

</template>
  
  <script>
   import CardOption from '@/components/cards/CardOption.vue';
  export default {
    components:{
        CardOption
    }
  }
  </script>

<style lang="scss" scoped>
    .contenedor-option{
      a {
        color: inherit;
        text-decoration: none;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        font: inherit;
        vertical-align: baseline;
        cursor: inherit;
        outline: none;
      }
    }
</style>
  
  
   