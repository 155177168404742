<template>
  <div class="content-two">
    <div class="regresar">
      <router-link to="/" class="link">
        <v-icon size="x-large" icon="mdi-arrow-left-circle"></v-icon><span>{{ $t('index.regresar') }}</span>
      </router-link>
    </div>
    <div class="content-body">
      <v-container>
        <v-row>
          <v-col  cols="12" sm="12" md="12">
            <span class="name-section">{{ $t('sections.subtitle-two') }}</span>
            <p class="intro">
              {{ $t('section-two.introduction') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col  cols="12" sm="12" md="12">
            <v-carousel
                delimiter-icon="mdi-square"
                :height="isSmallPhoneOnly ? 550 : 300"
                hide-delimiter-background
                show-arrows="hover"
            >

              <v-carousel-item>
                <v-sheet
                    color="#A38902"
                    height="100%"
                    tile
                >
                  <v-row>
                    <v-col  cols="12" sm="6" md="6" class="project">
                      <h3>Beter</h3>
                      <h4><v-icon color="white" icon="mdi-web"></v-icon> Sitio: <a  href="https://beter.mx" target="_blank">beter.mx</a></h4>
                      <p>
                        {{ $t('section-two.beter') }}
                      </p>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <img src="@/assets/img/proyectos/beter.png" alt="beter app">
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet
                    color="#A38902"
                    height="100%"
                    tile
                >
                  <v-row>
                    <v-col  cols="12" sm="6" md="6" class="project" >
                      <h3>Elevation</h3>
                      <h4><v-icon color="white" icon="mdi-web"></v-icon> Sitio: <a  href="https://elevationai.com/" target="_blank">elevationai.com</a></h4>
                      <p>
                        {{ $t('section-two.elevation') }}
                      </p>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6" class="d-flex">
                      <img src="@/assets/img/proyectos/elevation.png" class="border" alt="beter app">
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet
                    color="#A38902"
                    height="100%"
                    tile
                >
                  <v-row>
                    <v-col  cols="12" sm="6" md="6" class="project">
                      <h3>Futgol</h3>
                      <p>
                        {{ $t('section-two.futgol') }}
                      </p>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <img src="@/assets/img/proyectos/futgol.jpg" class="border" alt="beter app">
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet
                    color="#A38902"
                    height="100%"
                    tile
                >
                  <v-row>
                    <v-col  cols="12" sm="6" md="6" class="project">
                      <h3>Arrively</h3>
                      <p>
                        {{ $t('section-two.arrively') }}
                      </p>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6" class="d-flex">
                      <img src="@/assets/img/proyectos/arrively.png" class="border" alt="beter app">
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>



            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    isSmallPhoneOnly() {
      if (this.$vuetify && this.$vuetify.display) {
        return this.$vuetify.display.smAndDown;
      }else{
        console.error('Vuetify or breakpoint is not available');
      }
      return false;
    },
  }
}
</script>

<style lang="scss">
.content-two {
  padding: 20px;
  border-radius: 10px;
  .regresar{
    margin-bottom: 5px;
    a, a:visited, a:hover, a:active, a:focus {
      text-decoration: none;
      color: white;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      font: inherit;
      line-height: normal;
      outline: none; /* Para quitar el borde de enfoque */
      display: flex;
      span{
        font-size: 25px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .regresar, .content-body{
    padding: 30px;
    background: #A38902;
    color: white;
    border-radius: 20px;
    .intro{
      font-size: 14px;
      font-weight: 200;
    }
  }
  .content-body{
    .name-section{
      font-weight: 700;
    }
    p{
      margin-top: 30px;
    }
    .v-btn__content{
      i, .v-icon, .v-icon::before, i::before{
        color: black !important;
      }
      .mdi-chevron-left{
        color: black;
      }
    }
    .v-carousel-item{
      img{
        width: 100%;
        &.border{
          border-radius: 25px;
        }
      }
      i, .v-icon{
        color: black ;
      }
    }
    i, .v-icon{
      color: black;
    }

    .project{
      p{
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .content-two{
    padding:0px;
    .regresar, .content-body{
      padding: 5px !important;
    }
  }
}
</style>
