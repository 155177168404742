<template>
  <v-container>
    <v-row>
      <v-col  cols="12" sm="12" md="4" class="pa-8 contain-info-home" v-if="!showInfo">
        <InfoHome/>
      </v-col>
      <v-col  cols="12" sm="12" md="8" class="pa-8" >
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </v-col>
    </v-row>
  </v-container>
  <div class="btn-translate">
    <v-menu >
      <template v-slot:activator="{ props }">
        <v-btn icon="mdi-translate-variant" v-bind="props"></v-btn>
      </template>

      <v-list >
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="setLanguage(item.code)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>

</template>

<script>
import InfoHome from '@/components/InfoHome.vue';

export default {
  data: () => ({
    items: [
      { title: 'English', code: 'en' },
      { title: 'Español', code: 'es' },
    ],
  }),
  components: {
    InfoHome,
  },
  computed:{
    showInfo(){
      return this.$route.path !== '/' && this.isSmallPhoneOnly;
    },
    isSmallPhoneOnly() {
      if (this.$vuetify && this.$vuetify.display) {
        return this.$vuetify.display.smAndDown;
      }else{
        console.error('Vuetify or breakpoint is not available');
      }
      return false;
    },

  },
  mounted() {
    this.checkLanguageFromQuery();
  },
  watch: {
    '$route.query.t': 'checkLanguageFromQuery'
  },
  methods: {
    setLanguage(code) {
      this.$i18n.locale = code;
    },
    checkLanguageFromQuery() {
      const language = this.$route.query.t;
      if (language == 'es') {
        this.setLanguage(language);
      } else {
        console.log('No language parameter found in query.');
      }
    }
  }
};
</script>

<style lang="scss">

body {
  background-color: #042B2F;
  display: flex;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
}

#app {
  display: flex;
  width: 100vw;
  padding: 0;
  margin: 0;
}

.contain-info-home{
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.btn-translate{
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
}

.btn-translate .v-list-item {
  cursor: pointer; /* Cursor como puntero en los ítems del menú */
}

</style>
