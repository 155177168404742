<template>
  <div :class="{'option': true, 'revert-class': revert}"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
  >
    <div :class="{'info':true, 'movil-size': isSmallPhoneOnly, 'animate': !isSmallPhoneOnly }" :style="getBackgroundColor()">
      <div :class="cover" v-if="isSmallPhoneOnly"></div>
      <span class="title">{{title}}</span>
      <span class="sub-title">{{subTitle}}</span>
      <div class="numero" :style="getColor()" v-if="isSmallPhoneOnly">{{number}}</div>
    </div>
    <div :class="{'imagen':true}"  v-if="!isSmallPhoneOnly">
      <div :class="cover"></div>
      <div class="numero" :style="getColor()">{{number}}</div>
      <svg class="flecha":style="getFill()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false"
           style="user-select: none;  display: inline-block;
           flex-shrink: 0;"><g weight="light">
        <path d="M198,64V168a6,6,0,0,1-12,0V78.48L68.24,196.24a6,6,0,0,1-8.48-8.48L177.52,70H88a6,6,0,0,1,0-12H192A6,6,0,0,1,198,64Z">
        </path></g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    revert: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    number: {
      type: String,
      required: true
    },
    hoverBgColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      arrowImage: null,
      isHovered: false
    };
  },
  created() {
    this.loadImages();
  },
  methods: {
    async loadImages() {
      const arrow = await import('@/assets/img/arrow.svg');
      this.arrowImage = arrow.default;
    },
    resolveImageUrl(imagePath) {
      return new URL(`../assets/img/cover/${imagePath}`, import.meta.url).href;
    },
    getBackgroundColor() {
      return {
        background: this.isHovered ? this.hoverBgColor : '#1F475B'
      };
    },
    getColor() {
      return {
        color: this.isHovered ? this.hoverBgColor : 'white'
      };
    },
    getFill() {
      return {
        fill: this.isHovered ? this.hoverBgColor : 'white',
        stroke: this.isHovered ? this.hoverBgColor : 'white'
      };
    },
    handleMouseEnter() {
      this.isHovered = true;
    },
    handleMouseLeave() {
      this.isHovered = false;
    }
  },
  computed:{
    cover(){
      let classCover = ['overlay', ];
      switch (this.number) {
        case '01':
          classCover.push('cover-one');
          break;
        case '02':
          classCover.push('cover-two');
          break;
        case '03':
          classCover.push('cover-three');
          break;
        case '04':
          classCover.push('cover-four');
          break;
        case '05':
          classCover.push('cover-five');
          break;
          default:
        case '01':
          classCover.push('cover-six');
          break;
            break;
      }
      return classCover.join(' ');
    },
    isSmallPhoneOnly() {
      if (this.$vuetify && this.$vuetify.display) {
        return this.$vuetify.display.smAndDown;
      }else{
        console.error('Vuetify or breakpoint is not available');
      }
      return false;
    },
    mounted () {
    },
  }
}
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    .info.animate {
      width: 50%;
      .title, .sub-title {
        transform: translateX(120px);
      }
    }
    .imagen.animate {
      width: 50%;
      .numero {
        opacity: 1;
        transform: translateY(-40px);
      }
      .flecha{
        opacity: 1;
      }
    }
  }

  .info, .imagen {
    height: 280px;
    border-radius: 25px;
    color: white;
    padding: 15px;
    overflow: hidden;
  }
  &.revert-class {
    flex-direction: row-reverse;
  }
  .info {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: end;
    background: #1F475B;
    transition: width 0.3s ease-in-out, background 0.3s ease-in-out;
    &.movil-size{
      width: 100% !important;
      position: relative;
    }
    .title {
      font-size: 26px;
      width: 130px;
      line-height: 1;
      margin-bottom: 15px;
      transition: transform 0.3s ease-in-out;
      z-index: 2;
    }
    .sub-title {
      font-size: 26px;
      width: 130px;
      font-weight: bold;
      transition: transform 0.3s ease-in-out;
      z-index: 2;
    }
    .numero{
      position: absolute;
      right: 15px;
      bottom: -10px;
      font-size: 85px;
      z-index: 2;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &.cover-one{
        background: url('@/assets/img/cover/cover-bg.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.8); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-two{
        background: url('@/assets/img/cover/cover-bg-one.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.8); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-three{
        background: url('@/assets/img/cover/cover-bg-two.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.8); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-four{
        background: url('@/assets/img/cover/cover-bg-three.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.8); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-five{
        background: url('@/assets/img/cover/cover-bg-four.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.8); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-six{
        background: url('@/assets/img/cover/cover-bg-five.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.8); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
    }
  }
  .imagen {
    width: 68%;
    position: relative;
    transition: width 0.3s ease-in-out;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &.cover-one{
        background: url('@/assets/img/cover/cover-bg.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.4); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-two{
        background: url('@/assets/img/cover/cover-bg-one.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.4); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-three{
        background: url('@/assets/img/cover/cover-bg-two.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.4); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-four{
        background: url('@/assets/img/cover/cover-bg-three.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.4); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-five{
        background: url('@/assets/img/cover/cover-bg-four.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.4); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
      &.cover-six{
        background: url('@/assets/img/cover/cover-bg-five.webp') no-repeat center center, #1F475B; /* Imagen y color de fondo */
        background-size: cover;
        background-blend-mode: overlay; /* Mezcla de color y fondo */
        filter: brightness(0.4); /* Ajusta la luminosidad para oscurecer la imagen */
        z-index: 1;
      }
    }
    .numero {
      color: white;
      z-index: 2;
      position: absolute;
      bottom: -140px;
      font-size: 230px;
      left: 0;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
  }
  .flecha{
    border-style: none;
    position: absolute;
    top: 0;
    z-index: 02;
    width: 100px;
    fill: white;
    stroke: white;
    right: 0px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}
</style>
