<template>
  <div class="content-five">
    <div class="regresar">
      <router-link to="/" class="link">
        <v-icon size="x-large" icon="mdi-arrow-left-circle"></v-icon><span>{{ $t('index.regresar') }}</span>
      </router-link>
    </div>
    <div class="content-body">
      <v-container>
        <v-row >
          <v-col cols="12" sm="12" md="12" class="d-flex justify-center align-center">
            <v-sheet
                :elevation="2"
                :height="isSmallPhoneOnly ? '100%' : 500"
                :width="isSmallPhoneOnly ? '100%' : 500"
                border
                color="#2E507F"
                rounded
                class="cont-contactanos"
            >
              <h2>
                {{ $t('section-five.title-one') }}
              </h2>
              <p>
                {{ $t('section-five.p-one') }}
              </p>
              <ul>
                <li>
                  <v-btn prepend-icon="mdi-whatsapp" rounded="xl"
                         component="a"
                         href="https://wa.me/529991989445"
                         target="_blank"
                  >
                    {{ $t('section-five.btn-whats') }}
                  </v-btn>
                </li>
                <li>
                  <v-btn prepend-icon="mdi-phone" rounded="xl"
                         component="a"
                         @click="handlePhoneClick">
                    {{ $t('section-five.number') }}
                  </v-btn>
                </li>
                <li>
                  <v-btn prepend-icon="mdi-email-outline" rounded="xl"
                         component="a"
                         @click="handleEmailClick">
                    {{ $t('section-five.email') }}
                  </v-btn>
                </li>
                <li>
                  <v-btn prepend-icon="mdi-download-box" rounded="xl"
                         component="a"
                         :href="getLenguajePortafolio"
                         download
                  >
                    {{isSmallPhoneOnly ?  $t('section-five.btn-portafolio') : $t('section-five.btn-portafolio-long') }}
                  </v-btn>
                </li>
                <li>
                  <ul class="cont-sub-menu">
                    <li>
                      <v-btn icon="mdi-facebook" size="x-large"
                             component="a"
                             href="https://www.facebook.com/Beterapp"
                             target="_blank"
                      ></v-btn>
                    </li>
                    <li>
                      <v-btn icon="mdi-instagram" size="x-large"
                             component="a"
                             href="https://www.instagram.com/beter_app/"
                             target="_blank"
                      ></v-btn>
                    </li>
                    <li>
                      <v-btn icon="mdi-linkedin" size="x-large"
                             component="a"
                             href="https://www.linkedin.com/company/beterapp/"
                             target="_blank"
                      ></v-btn>
                    </li>
                  </ul>
                </li>
              </ul>

            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-snackbar
        v-model="snackbar"
        timeout="5000"
    >
      {{ $t('section-five.msj') }}

      <template v-slot:actions>
        <v-btn
            color="blue"
            variant="text"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
export default {
  data(){
    return{
      snackbar:false
    }
  },
  computed:{
    isSmallPhoneOnly() {
      if (this.$vuetify && this.$vuetify.display) {
        return this.$vuetify.display.smAndDown;
      }else{
        console.error('Vuetify or breakpoint is not available');
      }
      return false;
    },
    getLenguajePortafolio() {

      return `file/portafolio_${this.$i18n.locale}.pdf`;
    }
  },
  methods: {
    handlePhoneClick() {
      if (this.isMobileDevice()) {
        // Si es un dispositivo móvil, redirige a la app de llamadas
        window.location.href = `tel:${this.getPhoneNumber()}`;
      } else {
        // Si es escritorio, copia el número al portapapeles
        this.copyToClipboard(this.getPhoneNumber());
        this.snackbar = true;
      }
    },
    handleEmailClick() {
      if (this.isMobileDevice()) {
        // Si es un dispositivo móvil, copia el email al portapapeles
        this.copyToClipboard(this.getEmail());
        this.snackbar = true;
        window.location.href = `mailto:${this.getEmail()}`;
      } else {
        this.copyToClipboard(this.getEmail());
        this.snackbar = true;
        // Si es escritorio, abre la aplicación de correo
        window.location.href = `mailto:${this.getEmail()}`;
      }
    },
    isMobileDevice() {
      // Lógica para determinar si es un dispositivo móvil o no
      return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
    },
    getPhoneNumber() {
      // Aquí debes retornar el número de teléfono que deseas usar
      return '529991989445';
    },
    getEmail() {
      // Aquí debes retornar el correo electrónico que deseas usar
      return 'admin@beterapp.com';
    },
    copyToClipboard(text) {
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    }
  }
};
</script>
<style scoped lang="scss">
.content-five {
  padding: 20px;
  border-radius: 10px;
  .regresar{
    margin-bottom: 5px;
    a, a:visited, a:hover, a:active, a:focus {
      text-decoration: none;
      color: white;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      font: inherit;
      line-height: normal;
      outline: none; /* Para quitar el borde de enfoque */
      display: flex;
      span{
        font-size: 25px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .regresar, .content-body{
    padding: 30px;
    background: #365A91;
    color: white;
    border-radius: 20px;
  }

  .content-body{
    .cont-contactanos{
      padding: 20px;
      h2{
        text-align: center;
      }
      p{
        text-align: center;
        padding-bottom: 15px;
        padding-top: 15px;
      }
      ul{
        list-style: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        li{
          padding: 10px;
          width: 80%;
          display: flex;
          justify-content: center;
          .v-btn{
            color: black;
            background: white;
          }
          span{
            padding-bottom: 5px;
            border-bottom: 1px solid white;
            width: 100%;
            a{
              color: inherit;
              text-decoration: none;
              background: none;
              border: none;
              margin: 0;
              padding: 0;
              font: inherit;
              vertical-align: baseline;
              cursor: pointer;
              outline: none;
            }
          }
          ul{
            display: flex;
            flex-direction: row;

            li{
              border-bottom: none;
              justify-content: center;
              .v-btn{
                color: black;
                background: white;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .content-five{
    padding:0px;
    .regresar, .content-body{
      padding: 5px !important;
    }

    .content-body{
      .cont-contactanos{
        ul{
          li{
            width: 100%;
          }
        }
      }
    }
  }
}



</style>
