import { createRouter, createWebHistory } from 'vue-router'
import OptionsHome from  '@/components/OptionsHome.vue';
import Content01 from '@/components/content/Content01.vue';
import Content02 from '@/components/content/Content02.vue';
import Content03 from '@/components/content/Content03.vue';
import Content04 from '@/components/content/Content04.vue';
import Content05 from '@/components/content/Content05.vue';
import Content06 from '@/components/content/Content06.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: OptionsHome
    },
    { path: '/services', component: Content01 },
    { path: '/projects', component: Content02 },
    { path: '/innovation', component: Content03 },
    { path: '/answers', component: Content04 },
    { path: '/contact', component: Content05 },
  ]
})

export default router
