<template>
  <div :class="{'info-home':true, 'position-static': isDesktopOnly}" >
    <img src="@/assets/img/log-beter-dorado.svg" alt="logo beter">
    <div class="texto-1"> {{ $t('index.title-one') }}</div>
    <div class="texto-2">{{ $t('index.title-sub') }}</div>
    <div class="texto-3">{{ $t('index.parrafo') }}</div>
    <div class="texto-4">
      <v-btn prepend-icon="mdi-whatsapp" rounded="xl"
             component="a"
             href="https://wa.me/529991989445"
             target="_blank"
      >
        {{ $t('index.btn-whats') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isDesktopOnly() {
      if (this.$vuetify && this.$vuetify.display) {
        return this.$vuetify.display.mdAndUp;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.info-home {
  max-width: 400px;
  color: white;
  &.position-static{
    position: fixed !important;
  }

  .texto-1 {
    font-size: 12px;
    color: white;
    max-width: 320px;
    margin-top: 35px;
  }

  .texto-2 {
    font-size: 44px;
    max-width: 320px;
    color: #EBAB4D;
    line-height: 1.1;
    font-weight: 600;
    margin-top: 35px;
  }

  .texto-3 {
    font-size: 16px;
    color: white;
    margin-top: 35px;
    max-width: 320px;
  }

  .texto-4 {
    font-size: 16px;
    color: black;
    max-width: 320px;
    margin-top: 35px;
    .v-btn{
      color: black;
      background: white;
    }
  }
}
</style>
  
  
  