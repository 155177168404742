<template>
  <div class="content-one">
    <div class="regresar">
      <router-link to="/" class="link">
        <v-icon size="x-large" icon="mdi-arrow-left-circle"></v-icon><span>{{ $t('index.regresar') }}</span>
      </router-link>
    </div>
    <div class="content-body">
      <v-container>
        <v-row>
          <v-col  cols="12" sm="12" md="12">
            <span class="name-section">{{ $t('sections.subtitle-one') }}</span>
            <p class="intro">
              {{ $t('section-one.introduction') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="item-servicio">
          <v-col  cols="12" sm="6" md="6" class="texto-order left">
            <h3>
              {{ $t('section-one.web-title') }}
            </h3>
            <p>
              {{ $t('section-one.web-content') }}
            </p>
          </v-col>
          <v-col  cols="12" sm="6" md="6" >
            <img src="@/assets/img/sections/section-one-inavacion.webp" class="circle animated-border" alt="beter web">
          </v-col>
        </v-row>
        <v-row class="item-servicio revert">
          <v-col  cols="12" sm="6" md="6">
            <img src="@/assets/img/sections/section-one-movil.webp" alt="beter web" class="circle animated-border" >
          </v-col>
          <v-col  cols="12" sm="6" md="6" class="texto-order right">
            <h3>
              {{ $t('section-one.app-title') }}
            </h3>
            <p>
              {{ $t('section-one.app-content') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="item-servicio">
          <v-col  cols="12" sm="6" md="6" class="texto-order left">
            <h3>
              {{ $t('section-one.advice-title') }}
            </h3>
            <p>
              {{ $t('section-one.advice-content') }}
            </p>
          </v-col>
          <v-col  cols="12" sm="6" md="6">
            <img src="@/assets/img/sections/section-one-empresarial.webp" alt="beter web" class="circle animated-border" >
          </v-col>
        </v-row>
      </v-container>
    </div>

  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.content-one {
  padding: 20px;
  border-radius: 10px;
  .regresar{
    margin-bottom: 5px;
    a, a:visited, a:hover, a:active, a:focus {
      text-decoration: none;
      color: white;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      font: inherit;
      line-height: normal;
      outline: none; /* Para quitar el borde de enfoque */
      display: flex;
      span{
        font-size: 25px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .regresar, .content-body{
    padding: 30px;
    background: #B37A35;
    color: white;
    border-radius: 20px;
    .intro{
      font-size: 14px;
      font-weight: 200;
    }
  }

  .content-body{
    .circle{
      width: 100%;
      border-radius: 50%;
    }
    .animated-border {
      position: relative;
      border-radius: 50%;
      padding: 10px;
      animation: borderAnimation 2s infinite;
    }
    .texto-order{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      &.left{
        text-align: left;
      }
      &.right{
        text-align: right;
      }
      h3{
        font-weight: 700;
      }
      p{
        font-size: 15px;
        font-weight: 300;
      }
    }

    .name-section{
      font-weight: 700;
    }
    p{
      margin-top: 30px;
    }
    .item-servicio{
      margin-top: -20px;
    }

    @keyframes borderAnimation {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 160, 77, 0.8);
      }
      100% {
        box-shadow: 0 0 0 10px rgba(255, 160, 77, 0);
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  .content-one{
    padding:0px;
    .regresar, .content-body{
      padding: 5px;
      .item-servicio {
        &.revert{
          display: flex;
          flex-direction: column-reverse;
        }
      }
      .texto-order{
        &.left, &.right{
          text-align: left;
        }
      }
      .circle{

      }
    }
  }
}
</style>